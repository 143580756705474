"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18n = {
    borderKnobResizeMessage: { ja: "クリックするとメッセージ入力欄のサイズが変わります", en: "Click to resize message area" },
    supportPage: { ja: "サポートルーム", en: "Supports" },
    supportsPageNoSupports: { ja: "ルームを選択してください", en: "Please select a room" },
    supportStartTime: { ja: "開始", en: "Start Time" },
    supportHoursAgo: { ja: "時間前", en: "Hours Ago" },
    supportFromCustomer: { ja: "来訪者より", en: "From Customer" },
    supportFromOperator: { ja: "接客者より", en: "From Operator" },
    supportFromCustomerShortForm: { ja: "接", en: "U" },
    supportFromOperatorShortForm: { ja: "来", en: "R" },
    supportDetailsHeaderTitle: { ja: "詳細", en: "info" },
    supportEndedToastMessage: { ja: "サポートが終了しました", en: "Support Ended" },
    uploadSuccessMessage: { ja: "アップロードに成功しました", en: "Upload Succeeded" },
    uploadFailureMessage: { ja: "アップロードに失敗しました", en: "Upload Failed" },
    supportAssignmentModalHeader: { ja: "担当者変更", en: "Assign an Operator" },
    supportAssignmentModalUser: { ja: "ユーザー", en: "User" },
    supportAssignmentModalSupportRatio: { ja: "接客数/上限", en: "Assigned / Max Supports" },
    supportAssignmentModalOccupancyRate: { ja: "稼働率", en: "Occupancy Rate" },
    supportAssignmentModalStatus: { ja: "ステータス", en: "Status" },
    supportAssignmentModalSubmit: { ja: "変更", en: "Submit" },
    supportAssignmentButtonIgnore: { ja: "無視", en: "Ignore" },
    supportAssignmentButtonEnd: { ja: "完了する", en: "End" },
    supportAssignmentButtonReOpen: { ja: "再開する", en: "Reopen" },
    supportAssignmentButtonAssign: { ja: "アサイン", en: "Assign" },
    supportAssignmentButtonSelfAssign: { ja: "セルフ", en: "Self Assign" },
    supportAssignedToastMessage: { ja: "サポートが割り当てられました", en: "Support Assigned" },
    supportIgnoredToastMessage: { ja: "サポートは無視されました", en: "Support room has been ignored" },
    supportCreateLinkTypeMessage: { ja: "新規作成", en: "New" },
    supportCreateFileTypeMessage: { ja: "ファイルアップロード", en: "Upload File" },
    supportCreateLinkTypeMessageWindowTitle: { ja: "リンクタイプメッセージの作成", en: "Post link-type message" },
    supportCreateLinkTypeMessageModalSubmitButton: { ja: "投稿", en: "Post" },
    supportCreateLinkTypeMessagePosted: { ja: "リンクタイプメッセージを投稿しました", en: "Link-type message has successfully posted." },
    supportLinkMessageUrl: { ja: "URL", en: "URL" },
    supportLinkMessageTitle: { ja: "タイトル", en: "Title" },
    supportLinkMessageDescription: { ja: "本文", en: "Description" },
    supportLinkMessageImageUrl: { ja: "画像URL", en: "Image URL" },
    supportSelfEvaluateSubmit: { ja: "完了", en: "Done" },
    supportSelfEvaluateSelfComment: { ja: "コメントを入力できます", en: "Can submit a comment" },
    supportSelfEvaluateCompleted: { ja: "サポートを完了しました", en: "Support was completed." },
    supportSelfEvaluatePositive: { ja: "良い", en: "Positive" },
    supportSelfEvaluateNegative: { ja: "悪い", en: "Negative" },
    supportSelfEvaluatePlaceholder: { ja: "選択", en: "Select" },
    supportLastMessageKindUnreplied: { ja: "来訪者", en: "Customer" },
    supportLastMessageKindReplied: { ja: "接客者", en: "Operator" },
    supportLastMessageKindPlaceholder: { ja: "選択", en: "Select" },
    supportRoomRelationMember: { ja: "メンバー", en: "Member" },
    supportRoomRelationSupporting: { ja: "サポート", en: "Supporter" },
    supportRoomRelationWatching: { ja: "観察", en: "Watching" },
    supportRoomRelationAwaiting: { ja: "待", en: "Awaiting" },
    supportSortTypeRoomAscending: { ja: "ルーム作成日時（昇順）", en: "Room created date (Ascending)" },
    supportSortTypeRoomDescending: { ja: "ルーム作成日時（降順）", en: "Room created date (Descending)" },
    supportSortTypeLastMessageAscending: { ja: "最後のメッセージ作成日時（昇順）", en: "Message created date (Ascending)" },
    supportSortTypeLastMessageDescending: { ja: "最後のメッセージ作成日時（降順）", en: "Message created date (Descending)" },
    supportLoadPreviousMessages: { ja: "↑ 前のメッセージ ↑", en: "Load previous messages" },
    supportLoadNextMessages: { ja: "↓ 後のメッセージ ↓", en: "Load next messages" },
    toastRoomAssignedToOtherOperator: { ja: "別のオペレータにアサインしました", en: "This support was assigned to another operator." },
    customersPage: { ja: "来訪者情報", en: "Customers" },
    customersPageLoadingData: { ja: "データロード中", en: "Loading" },
    customersPageCustomerNotFound: { ja: "該当する来訪者が見つかりません", en: "Customers not found" },
    customersPagePleaseSelectSearchCriteria: { ja: "検索条件を選択してください", en: "Please select search for a customer" },
    customersPageChangeSearchConditionMessage: { ja: "検索条件変更", en: "Search Customers" },
    customersPageModalTitle: { ja: "検索条件変更", en: "Search Customers" },
    customersPageUncheckAll: { ja: "すべて解除", en: "uncheck all" },
    customersPageCheckAll: { ja: "すべて選択", en: "check all" },
    customersPageDisplayNameSearchCriteria: { ja: "表示名", en: "Display Name" },
    customersPageRoomNameSearchCriteria: { ja: "ルーム名", en: "Room Name" },
    customersPageSeeMore: { ja: "もっと見る", en: "See More" },
    customerDetailsHeader: { ja: "来訪者詳細", en: "Customer Details" },
    customerDetailsNumberOfRooms: { ja: "ルーム数", en: "Number of Rooms" },
    customerDetailsArchiveCustomer: { ja: "来訪者をアーカイブする", en: "Archive customer" },
    customerDetailsCustomerInformation: { ja: "ユーザー情報", en: "User Info" },
    customerDetailsCustomerId: { ja: "来訪者ID", en: "Customer ID" },
    customerDetailsMemo: { ja: "メモ", en: "Memo" },
    customerDetailsRoomList: { ja: "ルーム一覧", en: "Room List" },
    customerDetailsLog: { ja: "ログ", en: "Log" },
    customerDetailsTrackingInfoPageview: { ja: "トラッキング情報", en: "Tracking" },
    customerDetailsTrackingInfoEvent: { ja: "イベント", en: "Event" },
    customerDetailsOkSkyUUID: { ja: "OK SKY UUID", en: "OK SKY UUID" },
    customerDetailsCreationDate: { ja: "作成日時", en: "Creation Date" },
    customerDetailsSource: { ja: "遷移元", en: "Source" },
    customerDetailsMemoPlaceholder: { ja: "来訪者情報メモ", en: "Customer Info Memo" },
    customerDetailsRoomName: { ja: "ルーム名", en: "Room Name" },
    customerDetailsType: { ja: "種別", en: "Type" },
    faqPage: { ja: "FAQ", en: "FAQ" },
    overviewPage: { ja: "サポート状況", en: "Overview" },
    messagesPage: { ja: "メッセージの検索", en: "Messages" },
    messagesPageNoMessages: { ja: "メッセージが見つかりません", en: "No Messages Loaded" },
    messagesPageSearchWithRegexp: { ja: "正規表現を用いる", en: "Search with regexp" },
    messagesPageCSVDownloadToastMessage: { ja: "ダウンロードされました", en: "All messages have been downloaded." },
    pastSupportsPage: { ja: "過去のサポート", en: "Past Supports" },
    pastSupportspageActive: { ja: "アクティブ", en: "Active" },
    pastSupportsPageEnded: { ja: "終了しました", en: "Ended" },
    pastSupportsPageBoth: { ja: "どちらも", en: "Both" },
    pastSupportsPageStatusLabel: { ja: "状態", en: "Status" },
    pastSupportsPageSelfEvaluateLabel: { ja: "自己評価", en: "Self Evaluation" },
    pastSupportsPageLastMessageKindLabel: { ja: "最後のメッセージ", en: "Last message" },
    pastSupportsPageNotLoaded: { ja: "サポートはありません", en: "Supports Not Loaded" },
    dashboardPage: { ja: "ダッシュボード", en: "Dashboard" },
    draftMessagesPage: { ja: "下書きメッセージ", en: "Support Draft Messages" },
    draftMessagesPageNotLoaded: { ja: "下書きメッセージはありません", en: "No Support Draft Messages Loaded" },
    draftMessagesApproveMessage: { ja: "承認", en: "Approve" },
    draftMessagesApprovedMessage: { ja: "承認済み", en: "Approved" },
    draftMessagesRejectMessage: { ja: "却下", en: "Reject" },
    draftMessagesRejectedMessage: { ja: "却下済み", en: "Rejected" },
    draftMessagesInReviewMessage: { ja: "レビュー中", en: "In Review" },
    draftMessagesReviewedMessage: { ja: "レビュー済", en: "Reviewed" },
    draftMessagesCommentMessage: { ja: "下書きメッセージのコメントを残してください", en: "Please leave a comment on the draft message" },
    draftMessagesCommentsTitle: { ja: "コメント", en: "Comments" },
    draftMessagesResolveMessage: { ja: "確認", en: "Resolve" },
    draftMessagesResolvedMessage: { ja: "すべて", en: "All" },
    draftMessagesDismissAllReadMessage: { ja: "すべての読み取りを閉じる", en: "Dismiss all read" },
    draftMessagesShowOlderMessage: { ja: "前のものを表示する", en: "Show older" },
    draftMessagesPageStatusLabel: { ja: "状態", en: "Status" },
    surveysPage: { ja: "アンケート", en: "Surveys" },
    surveysPageCreateNewSurvey: { ja: "新しいアンケートの作成", en: "Create New Survey" },
    surveysPageAllQuestions: { ja: "全質問・回答一覧", en: "All Questions" },
    surveysPageNoQuestions: { ja: "質問をまだしていません", en: "No Questions has been asked yet" },
    surveysPageNoAnswer: { ja: "返答はまだありません", en: "No answer yet" },
    surveysPageHowToCreateQuestions: {
        ja: "質問を作成するには、サポートルームでアンケートを選択してください。",
        en: "To ask a question, please go to a room and select a survey."
    },
    surveysPageNoSurveyChosen: { ja: "アンケートを選んでください", en: "Please choose a Survey" },
    surveysPageKind: { ja: "種類", en: "Kind" },
    surveysPageKindRadioChoice: { ja: "ラジオボタン", en: "Radio Button" },
    surveysPageKindSelectInput: { ja: "セレクトインプット", en: "Select Input" },
    surveysPageKindCard: { ja: "カード", en: "Card" },
    surveysPageTitle: { ja: "タイトル", en: "Title" },
    surveysPageDescription: { ja: "説明", en: "Description" },
    surveysPageImageUrl: { ja: "画像", en: "Image" },
    surveysPageImageAlt: { ja: "画像代替", en: "Image's alternative description" },
    surveysPageChoices: { ja: "選択肢", en: "Answer Choices" },
    surveysPageDisplayMessage: { ja: "表示されるメッセージ", en: "Message to be displayed" },
    surveysPageChoicesAction: { ja: "選択後のアクション", en: "Action to take when selected" },
    surveysPageChoicesActionCreateMessage: { ja: "メッセージ作成", en: "Create a message" },
    surveysPageLinks: { ja: "リンク", en: "Link" },
    surveysPageLinksUrl: { ja: "URL", en: "URL" },
    headerSupportPage: { ja: "サポートルーム", en: "Supports" },
    headerMembersPage: { ja: "メンバー", en: "Members" },
    headerCustomersPage: { ja: "来訪者情報", en: "Customers" },
    headerFaqPage: { ja: "FAQ", en: "FAQ" },
    headerOverviewPage: { ja: "接客者状況", en: "Overview" },
    headerMessagesPage: { ja: "メッセージの検索", en: "Search Messages" },
    headerPastSupportsPage: { ja: "過去のサポート", en: "Search Past Supports" },
    headerDraftMessagesPage: { ja: "下書きメッセージ", en: "Support Draft Messages" },
    headerDashboardPage: { ja: "ダッシュボード", en: "Dashboard" },
    headerSurveysPage: { ja: "アンケート", en: "Surveys" },
    headerChatsPage: { ja: "ウィジェット", en: "Clients" },
    headerMasterKeywordsPage: { ja: "マスターキーワード", en: "Master Keywords" },
    headerReportsPage: { ja: "統計情報", en: "Reports" },
    headerWebhookPage: { ja: "Webhook", en: "Webhook" },
    headerMarketingDatasPage: { ja: "行動フロー", en: "Action Flow" },
    headerScenarioTemplatesPage: { ja: "シナリオbot設定", en: "Scenario bot settings" },
    headerConnectionPage: { ja: "コネクション", en: "Connection" },
    emailAndNameSearchPlaceholder: { ja: "名前、メールアドレスで検索", en: "Search by name or email address" },
    supportLeftFilteringSearchRoomPlaceholder: { ja: "ルーム名検索", en: "Search Room" },
    supportLeftFilteringFilterOperatorPlaceholder: { ja: "オペレータによる", en: "Filter Operator" },
    supportLeftFilteringSupportingFilter: { ja: "接客中", en: "Supporting" },
    supportLeftFilteringAwaitingSupportFilter: { ja: "接客待ち", en: "Awaiting" },
    supportLeftFilteringUnrepliedFilter: { ja: "未返信", en: "Unreplied" },
    supportLeftFilteringRepliedFilter: { ja: "返信済", en: "Replied" },
    supportLeftFilteringAllOperatorDropdown: { ja: "全オペレーター", en: "All Operators" },
    supportLeftFilteringAllRoomsDropdown: { ja: "全ルーム", en: "All Rooms" },
    supportLeftFilteringSearchNoResults: { ja: "結果がありません", en: "No Results" },
    customersPageSearchCondition: { ja: "検索条件", en: "Search Conditions" },
    customersPageTableHeaderDisplayName: { ja: "表示名", en: "Display Name" },
    customersPageTableHeaderAttributes: { ja: "属性", en: "Attributes" },
    customersPageTableHeaderCreationDate: { ja: "作成日時", en: "Creation Date" },
    customersPageTableNoCustomersLoaded: { ja: "検索条件を選択してください", en: "Please search for customers" },
    customersPageResultCounter: { ja: "件", en: "Results" },
    popupRoomEmptyMessage: { ja: "お問い合わせ内容を下のメッセージボックスに入力し、送信してください。", en: "Please send a message." },
    popupRoomSendOnEnterMessage: { ja: "メッセージを入力してください (Shift+Enter)で送信", en: "Press (Shift + Enter) to send" },
    popupRoomNotSendOnEnterMessage: { ja: "メッセージを入力してください (Enterで送信)", en: "Press the Shift Key to send" },
    membersPage: { ja: "メンバー", en: "Members" },
    membersPageAddNewMember: { ja: "メンバー追加", en: "New Member" },
    operatorModalUniqueUserId: { ja: "OK SKY UUID", en: "Unique ID" },
    operatorModalUniqueName: { ja: "ユニーク名", en: "Unique Name" },
    operatorModalMailAddress: { ja: "メールアドレス", en: "Email" },
    operatorModalFullName: { ja: "表示名", en: "Full Name (Display Name)" },
    operatorModalIcon: { ja: "アイコン", en: "Icon" },
    operatorModalMaxSupportCount: { ja: "自動アサイン上限数", en: "Max Support Count" },
    operatorModalAssignPriority: { ja: "アサイン優先度", en: "Assign priority" },
    operatorModalAssignPriorityDescription: { ja: "※ 数が大きいほど、役割の中でアサインされやすくなります。", en: "* Larger number is easier to be assigning within that role." },
    operatorModalTimeZone: { ja: "タイムゾーン", en: "Timezone" },
    operatorModalInTraining: { ja: "トレーニング中", en: "In Training" },
    operatorModalLanguage: { ja: "言語", en: "Language" },
    operatorModalSendAnEmailButton: { ja: "招待メールを送信", en: "Send an Invite" },
    operatorModalSoundNotification: { ja: "通知音を鳴らす", en: "Ring a notify sound" },
    operatorModalSoundNotificationType: { ja: "通知音を鳴らす条件", en: "Sound condition" },
    operatorModalSoundNotificationFollowing: { ja: "フォローしているまたは、接客待ちのルームにてメッセージを受信したとき", en: "When customer message was received at following or awaiting support rooms" },
    operatorModalSoundNotificationAll: { ja: "すべてのサポートでメッセージを受信したとき", en: "When customer message was received at all support rooms" },
    operatorModalRole: { ja: "役割", en: "Role" },
    operatorModalAccessToken: { ja: "アクセストークン", en: "Access Token" },
    operatorModalRegenerateAccessToken: { ja: "アクセストークンを更新する", en: "Regenerate Access Token" },
    accessTokenConfirmationMessage: {
        ja: "アクセストークンを更新してもよろしいですか？ 更新後、ログアウトされます",
        en: "Are you sure you want to regenerate the access token? You will be logged out for security"
    },
    membersPageTableHeaderIcon: { ja: "アイコン", en: "Icon" },
    membersPageTableHeaderFullName: { ja: "表示名", en: "Full Name (Display Name)" },
    membersPageTableHeaderEmail: { ja: "メールアドレス", en: "Email" },
    membersPageTableHeaderUniqueName: { ja: "ユニーク名", en: "Unique Name" },
    membersPageTableHeaderAssignPriority: { ja: "アサイン優先度", en: "Assign Priority" },
    membersPageTableHeaderRole: { ja: "役割", en: "Role" },
    membersPageTableHeaderActive: { ja: "アクティブ", en: "Is Active" },
    membersPageActivate: { ja: "アクティブにする", en: "activate" },
    membersPageDeactivate: { ja: "非アクティブにする", en: "deactivate" },
    masterKeywordsPage: { ja: "マスターキーワード", en: "Master Keywords" },
    masterKeywordsPageHeader: { ja: "マスターキーワード", en: "Master Keywords" },
    masterKeywordsTableHeaderKeyName: { ja: "名前", en: "Key Name" },
    masterKeywordsTableHeaderKeyTitle: { ja: "表示名", en: "Key Title" },
    masterKeywordsTableHeaderRank: { ja: "順番", en: "Rank" },
    masterKeywordsValidationErrorMessage: { ja: "小文字英数字＆＠＿ー", en: "Must be lower case letters and 0-9 &@_-" },
    masterKeywordsValidationOnlyNumberErrorMessage: { ja: "数値", en: "Must be numbers" },
    masterKeywordsDuplicationErrorMessage: { ja: "重複する", en: "Duplicate" },
    masterKeywordsAddNewMasterKeyword: { ja: "新しいマスターキーワード", en: "New Master Keyword" },
    analyticsPage: { ja: "アナリティクス", en: "Analytics" },
    analyticsPageActiveUsersRightNow: { ja: "現在アクティブなユーザー", en: "Active users right now" },
    analyticsPagePageViewsPerMinute: { ja: "閲覧数（1分）", en: "Page views per minute" },
    analyticsPagePagesViewed: { ja: "閲覧されたページ", en: "Pages Viewed" },
    clientsPage: { ja: "ウィジェット", en: "Widgets" },
    clientsPageAddClient: { ja: "新たにウィジェットを作成", en: "New Widget" },
    clientsPageDeleteConfirmation: { ja: "削除しても良いですか？", en: "Are you sure you want to delete" },
    clientsPageDefaultTitle: { ja: "新規ウィジェット", en: "Default Widget" },
    clientsPageNoClientChosen: { ja: "ウィジェットを選んでください", en: "Please choose a Widget" },
    clientDetailScreenName: { ja: "ウィジェット名", en: "Screen Name" },
    clientDetailEmbeddedTag: { ja: "埋め込みタグ", en: "Embedded Tag" },
    clientDetailChatScreenId: { ja: "ウィジェットID", en: "Screen ID" },
    clientDetailDesignSettings: { ja: "画面デザイン設定", en: "Design Settings" },
    clientDetailPreview: { ja: "プレビュー", en: "Preview" },
    clientDetailDefaultCss: { ja: "デフォルトCSS", en: "Default CSS" },
    clientDetailCustomCSS: { ja: "編集用CSS", en: "Custom CSS" },
    clientDetailChatIcon: { ja: "チャットアイコン", en: "Chat Icon" },
    clientDetailChatScreen: { ja: "チャット画面", en: "Chat Screen" },
    clientDetailShowHeader: { ja: "ヘッダーを表示", en: "Show Header" },
    clientDetailAutoPostSettings: { ja: "自動投稿設定（開始時）", en: "Auto Post Settings (When start a chat)" },
    clientDetailAwaitingPostSettings: { ja: "自動投稿設定（待機時・すべて）", en: "Auto message settings (For all awaiting users)" },
    clientDetailAwaitingPostDuringBusinessHourSettings: { ja: "自動投稿設定（待機時・営業時間内）", en: "Auto message settings (For awaiting users during business hours)" },
    clientDetailAwaitingPostAfterBusinessHourSettings: { ja: "自動投稿設定（待機時・営業時間外）", en: "Auto message settings (For awaiting users after business hours)" },
    clientDetailAutoNotificationSettings: { ja: "自動案内設定", en: "Auto notification settings" },
    clientDetailDisplayRules: { ja: "表示ルール", en: "Display Rules" },
    clientDetailScheduleSettings: { ja: "時間設定", en: "Schedule Settings" },
    clientDetailV2HeaderTitle: { ja: "ヘッダータイトル", en: "Header Title" },
    clientDetailV2HeaderDescription: { ja: "ヘッダー説明", en: "Header Description" },
    clientDetailV2ReplyGuidance: { ja: "返信目安", en: "Reply Guidance" },
    clientDetailDateFormat: { ja: "日付形式", en: "Date Format" },
    clientDetailV2RoomCreateButtonText: { ja: "ルーム作成ボタン内のテキスト", en: "Room Create Button Text" },
    clientDetailV2WidgetPosition: { ja: "ウィジェット位置", en: "Widget Position" },
    clientDetailV2WidgetPositionBottomRight: { ja: "右下", en: "Bottom Right" },
    clientDetailV2WidgetPositionBottomLeft: { ja: "左下", en: "Bottom Left" },
    clientDetailV2WidgetHeight: { ja: "高さ", en: "Height" },
    clientDetailV2WidgetWidth: { ja: "幅", en: "Width" },
    clientDetailV2ButtonImage: { ja: "ボタン画像", en: "Button Image" },
    clientDetailV2ButtonImageCustomWidth: { ja: "幅", en: "Width" },
    clientDetailV2ButtonImageCustomHeight: { ja: "高さ", en: "Height" },
    clientDetailV2ButtonImageCustomSizeCheckBox: { ja: "カスタムのサイズにする。（標準　幅:60px、高さ:60px)", en: "Custom Size On.(Default size, width:60px, height:60px)" },
    clientDetailV2ButtonImageCustomSizePx: { ja: "px", en: "px" },
    clientDetailV2OutlineColorTitle: { ja: "アウトラインカラー", en: "Outline color" },
    clientDetailV2OutlineColorDescription: {
        ja: "ウィジェット内をクリックした後タブキーを押し要素を選択すればこちらで選択したアウトラインの色が直接確認できます",
        en: "Click inside the widget and use the tab key to focus on an element to preview the chosen outline color"
    },
    clientDetailV2DownloadLink: { ja: "メッセージダウンロード機能", en: "Show message download link" },
    clientDetailV2DownloadLinkHide: { ja: "表示しない", en: "Hide" },
    clientDetailV2DownloadLinkShow: { ja: "表示する", en: "Show" },
    clientDetailV2CollectEmailsTitle: { ja: "メール収集のタイトル", en: "Collect Emails Title" },
    clientDetailV2CollectEmailsDescription: { ja: "メール収集の説明", en: "Collect Emails Description" },
    clientDetailV2CollectEmailsErrorMessage: { ja: "メール収集のエラーメセージ", en: "Collect Emails Error Message" },
    clientDetailCreateNewUserInfo: { ja: "新しいユーザー情報を作成", en: "Create new User Info" },
    clientDetailUserInfoKeyTitle: { ja: "マスターキーワード", en: "Master Keyword" },
    clientDetailUserInfoValue: { ja: "値", en: "Value" },
    clientDisplayRulesTitle: { ja: "表示する", en: "Display widget" },
    clientDuringOffBusinessHours: { ja: "営業時間外の場合", en: "During off-business hours" },
    clientCollectEmails: { ja: "メール収集", en: "Collect emails" },
    clientDontShowWidget: { ja: "ウィジェットを無効にする", en: "Don't show widget" },
    clientDisplayRulesUserRegistration: { ja: "ユーザー登録", en: "User Registration" },
    clientDisplayRulesNoRegistration: { ja: "登録なし", en: "Not Required" },
    clientDisplayRulesRegistrationOptional: { ja: "登録任意", en: "Registration Optional" },
    clientDisplayRulesRegistrationRequired: { ja: "登録必須", en: "Registration Required" },
    clientDisplayRulesEmbeddedWhiteList: { ja: "埋込ホワイトリスト", en: "Embedded White List" },
    clientDisplayRulesDisplayWhiteList: { ja: "表示ホワイトリスト", en: "Display White List" },
    clientDisplayRulesEmbeddedBlackList: { ja: "埋込ブラックリスト", en: "Embedded Black List" },
    clientDisplayRulesDisplayBlackList: { ja: "表示ブラックリスト", en: "Display Black List" },
    clientsAutoPostPoster: { ja: "投稿者", en: "Poster" },
    clientsAutoPostSelectPoster: { ja: "投稿者を選択", en: "Select Poster" },
    clientsAutoPostUnknownUser: { ja: "不明ユーザー", en: "Unknown User" },
    clientsAutoPostMessageContent: { ja: "投稿の内容", en: "WidgetMessage Content" },
    clientsAutoPostMessageContentPlaceholder: { ja: "メッセージ内容を入力", en: "Enter widget message content" },
    clientsAutoPostMessageKind: { ja: "投稿の種類", en: "WidgetMessage Kind" },
    clientsAutoPostSelectMessageKind: { ja: "投稿の種類を選択", en: "Select widget message Kind" },
    clientsAutoPostMessageSourceFile: { ja: "投稿するファイル", en: "File To attach" },
    clientsAutoPostMessageKindsAudioSrcUrl: { ja: "投稿する音声ファイル", en: "Audio File To Attach" },
    clientsAutoPostMessageKindsImageSrcUrl: { ja: "投稿する画像ファイル", en: "Image File To Attach" },
    clientsAutoPostMessageKindsVideoSrcUrl: { ja: "投稿する動画ファイル", en: "Video File To Attach" },
    clientsAutoPostMessageKindsVideoTagSrcUrl: { ja: "投稿する動画ファイル", en: "Video File To Attach" },
    clientsAutoPostMessageKindsYoutubeSrcUrl: { ja: "投稿するYoutube ID", en: "Youtube ID To Attach" },
    clientsAutoPostMessageKindsText: { ja: "テキスト", en: "Text" },
    clientsAutoPostMessageKindsAudio: { ja: "音声", en: "Audio" },
    clientsAutoPostMessageKindsFile: { ja: "ファイル", en: "File" },
    clientsAutoPostMessageKindsImage: { ja: "画像", en: "Image" },
    clientsAutoPostMessageKindsVideo: { ja: "動画", en: "Video" },
    clientsAutoPostMessageKindsVideoTag: { ja: "動画", en: "Video" },
    clientsAutoPostMessageKindsVideoTagNotFound: { ja: "動画を再生できません", en: "Video cannot be played" },
    clientsAutoPostUserErrorMessage: { ja: "ユーザーは必須です", en: "User is required" },
    clientsAutoPostKindErrorMessage: { ja: "メッセージタイプを選択", en: "Select a message type" },
    clientsAutoPostContentErrorMessage: { ja: "内容は必須です", en: "Content is required" },
    clientsNotificationBeforeClosingBusinessHour: { ja: "営業時間終了10分前アナウンス", en: "Notification 10 minutes before the end of business hours" },
    clientsNotificationPlaceholder: { ja: "アナウンス内容を入力", en: "Input content" },
    clientsNewAutoPostButton: { ja: "新しい自動メッセージ", en: "New Auto Post" },
    clientsPathListUrl: { ja: "URL", en: "URL" },
    clientsPathMatchFQDN: { ja: "FQDNまで一致", en: "Match FQDN" },
    clientsPathAddURL: { ja: "URLを追加", en: "Add URL" },
    clientsRulesEditorConditions: { ja: "条件", en: "Conditions" },
    clientsRulesEditor: { ja: "条件を追加", en: "Add Condition" },
    clientsRulesEditorPlaceholder: { ja: "始まる* | 一致 | *終わる | *含む*", en: "Starts * | Match | * Ends | * Included *" },
    clientsStartTime: { ja: "開始時間", en: "Start Time" },
    clientsEndTime: { ja: "終了時間", en: "End Time" },
    clientsSurveyMessage: { ja: "送信", en: "Send" },
    clientsSurveyUrl: { ja: "リンク先へ", en: "Go to" },
    widgetModeChatRoom: { ja: "チャットルーム", en: "Chat Room" },
    widgetModeRoomList: { ja: "チャットリスト", en: "Room List" },
    widgetModeMinimized: { ja: "最小化", en: "Minimized" },
    widgetModeSettings: { ja: "設定ページ", en: "Settings Page" },
    widgetModeOfflineAndCollectingEmails: { ja: "オフラインでのメール収集", en: "Offline & Collecting Emails" },
    widgetModePopup: { ja: "ポップアップメッセージ", en: "Popup Messages" },
    widgetClickToEdit: { ja: "ウィジェット内のアイテムをクリックして色を変更する", en: "Click the items inside the widget to change the colors" },
    widgetSelectState: { ja: "ウィジェットの状態をプレビューする", en: "Preview widget states" },
    widgetVersion2: { ja: "V2", en: "V2" },
    widgetCustomerFirstMessage: { ja: "こんにちは！", en: "Hello!" },
    widgetOperatorFirstMessage: { ja: "こんにちは", en: "Hi" },
    widgetCustomerSecondMessage: { ja: "こんばんは", en: "Goodnight" },
    widgetWritingMessage: { ja: "おはようございます", en: "Good morning" },
    widgetCollectEmailDescription: {
        ja: "※メールアドレスを登録すると、オペレーターからの新しいメッセージが届くようになります",
        en: "※Register your email address to get an notification when an operator sends a new message"
    },
    widgetCollectEmailCommand: { ja: "現在営業時間外です", en: "Outside business hours" },
    widgetCollectEmailMessage: { ja: "営業時間内に返信させて頂きます", en: "We will response to you during business hours" },
    widgetCollectEmailTimeDetail: { ja: "次の営業開始時刻 ", en: "We will be back in" },
    widgetCollectEmailDetail: { ja: "もうすぐ戻ります", en: "We will be back soon" },
    widgetScenarioChoice: { ja: "確認したい内容を選択してください。", en: "Please select the content you wish to check." },
    widgetScenarioChoiceMoveTop: { ja: "＜＜トップに戻る", en: "＜＜Back to Top" },
    widgetScenarioChoiceMoveBack: { ja: "＜＜前の設問に戻る", en: "＜＜Back to previous question" },
    widgetChangeHuman: { ja: "有人に切り替える", en: "Switch to manned" },
    csvExportDescription: {
        ja: "CSVが大きい場合エクスポートに時間がかかります、エクスポート中にページをリフレッシュしないでください",
        en: "Bigger CSV files might take a while to export, please do not refresh the page while it's exporting"
    },
    csvLocalDownloadMessage: { ja: "ファイルはこちらにダウンロードされました", en: "The has been downloaded in the following directory" },
    csvDownloadMessage: { ja: "ファイルはダウンロードされました", en: "The has been downloaded" },
    csvWidgetDownloadMessages: { ja: "現在のチャットルームのメッセージをダウンロード", en: "Download messages in current room." },
    fakePathText: { ja: "/Users/Username/room1.csv", en: "/Users/Username/room1.csv" },
    widgetColors: {
        ja: {
            widget_button: "ウィジェットボタンの背景カラー",
            widget_button_fill: "ウィジェット隠しボタンの画像カラー",
            widget_hide_button: "ウィジェット隠しボタンの背景カラー",
            widget_hide_button_fill: "ウィジェットボタンの画像カラー",
            background: "ウィジェットの背景カラー",
            background_text: "ウィジェットのテキストカラー",
            send_message_button_outline: "メッセージ送信ボタンの概要",
            message_date_text: "日付のカラー",
            operator_message_background: "オペレーターメッセージの背景カラー",
            operator_message_text: "オペレーターメッセージのテキストカラー",
            customer_message_background: "顧客メッセージの背景カラー",
            customer_message_text: "顧客メッセージのテキストカラー",
            widget_csv_download_button_background: "CSVダウンロードボタンのバックグラウンドカラー",
            widget_csv_download_button_fill: "CSVダウンロードボタンの線のカラー",
            save_settings_button_background: "設定ページ保存ボタンの背景カラー",
            save_settings_button_text: "設定ページ保存ボタンのテキストカラー",
            navigation_bar_background: "ナビゲーションバーの背景カラー",
            navigation_bar_text: "ナビゲーションバーのテキストカラー",
            navigation_bar_button_fill: "ナビゲーションバーボタンの画像カラー",
            room_create_button: "ルーム作成ボタンのカラー",
            room_create_button_fill: "ルーム作成ボタンのテキストカラー",
            room_list_room_background: "ルームへボタンの背景カラー",
            room_list_room_text: "ルームへボタンのテキストカラー",
            progress_bar: "プログレスバーのカラー",
            unread_message_background: "未読メッセージの背景カラー",
            unread_message_text: "未読メッセージのテキストカラー",
            scrollbar_background: "スクロールバーの背景",
            scrollbar_body: "スクロールバー本体",
            outline_color: "アウトラインカラー",
            collect_emails_background: "メール収集の背景カラー",
            collect_emails_text: "メール収集のテキストカラー",
            collect_emails_error: "メール収集のエラーカラー",
            collect_emails_description: "メール収集の説明カラー",
            settings_text: "設定画面のテキストカラー",
            settings_subtext: "設定画面のサブテキストカラー",
        },
        en: {
            widget_button: "Widget Button Background",
            widget_button_fill: "Widget Button Image",
            widget_hide_button: "Widget Hide Button Background",
            widget_hide_button_fill: "Widget Hide Button Image",
            background: "Widget Background",
            background_text: "Widget Text",
            send_message_button_outline: "Send WidgetMessage Button Outline",
            message_date_text: "Color of date",
            operator_message_background: "Operator WidgetMessage Background",
            operator_message_text: "Operator WidgetMessage Text",
            customer_message_background: "Customer WidgetMessage Background",
            customer_message_text: "Customer WidgetMessage Text",
            widget_csv_download_button_background: "CSV Download Button Background",
            widget_csv_download_button_fill: "CSV Download Button Lines",
            save_settings_button_background: "Settings Page Save Button Background ",
            save_settings_button_text: "Settings Page Save Button Text",
            navigation_bar_background: "Navigation Bar Background",
            navigation_bar_text: "Navigation Bar Text",
            navigation_bar_button_fill: "Navigation Bar Button Image",
            room_create_button: "Create Room Button",
            room_create_button_fill: "Create Room Button Text",
            room_list_room_background: "Room Access Button Background",
            room_list_room_text: "Room Access Button Text",
            progress_bar: "Progress Bar",
            unread_message_background: "Unread WidgetMessage Background",
            unread_message_text: "Unread WidgetMessage Text",
            scrollbar_background: "Scrollbar Background",
            scrollbar_body: "Scrollbar Body",
            outline_color: "Outline color",
            collect_emails_background: "Collect Emails Background",
            collect_emails_text: "Collect Emails Text",
            collect_emails_error: "Collect Emails Error",
            collect_emails_description: "Collect Emails Description",
            settings_text: "Settings Page Text",
            settings_subtext: "Settings Page Subtext",
        }
    },
    marketingDatasPage: { ja: "行動フロー", en: "Action Flow" },
    marketingDatasPageSearchCondition: { ja: "検索条件", en: "Search Condition" },
    marketingDatasPageStartPlaceholder: { ja: "集計期間 ここから", en: "Start Period" },
    marketingDatasPageEndPlaceholder: { ja: "集計期間 ここまで", en: "End Period" },
    marketingDatasPageSelectStartAndEndDate: { ja: "開始日と終了日を選択してください", en: "Please select start date and end date" },
    marketingDatasPageSelectlocation: { ja: "選択してください", en: "Please select this" },
    marketingDatasPageNextPagesButton: { ja: "＞", en: "＞" },
    marketingDatasPageNextPagesSelectedButton: { ja: "＞", en: "＞" },
    marketingDatasPageNextPagesOtherButton: { ja: "他", en: "..." },
    marketingDatasPageSummaryUserCountName: { ja: "ユーザ", en: "user" },
    marketingDatasPageSummarySessionCountName: { ja: "セッション", en: "session" },
    marketingDatasPageSummaryPvCountName: { ja: "PV", en: "PV" },
    marketingDatasPageSummaryWithdrawalRateName: { ja: "離脱率", en: "Withdrawal rate" },
    marketingDatasPageSummaryRateOfDirectReturnName: { ja: "直帰率", en: "Rate of direct return" },
    marketingDatasPageSummaryAverageLagTimeName: { ja: "平均滞在時間", en: "Average stay time" },
    marketingDatasPageSummaryNumberOfCVsName: { ja: "CV数", en: "Number of CVs" },
    marketingDatasPagePercentageUnit: { ja: "%", en: "%" },
    marketingDatasPageLandingPageName: { ja: "ランディングページ", en: "Landing Page" },
    marketingDatasPageFirstPageName: { ja: "最初のページ", en: "First page" },
    marketingDatasPagePageName: { ja: "ページ目", en: "Page" },
    marketingDatasPageNumberOfPeopleLeavingName: { ja: "離脱数", en: "Number of people leaving" },
    marketingDatasPageExplanatoryNotePageViewName: { ja: "ページ表示", en: "Page view" },
    marketingDatasPageExplanatoryNoteChatShowName: { ja: "チャット開く", en: "Open chat" },
    marketingDatasPageExplanatoryNoteChatHideName: { ja: "チャット閉じる", en: "Close chat" },
    marketingDatasPageExplanatoryNoteChatCloseName: { ja: "チャット非表示", en: "Hidden chat" },
    scenarioTemplatesPage: { ja: "シナリオbot設定", en: "Scenario bot settings" },
    webhooksPage: { ja: "Webhook", en: "Webhook" },
    webhookPageSearchPlaceholder: { ja: "送信先URLで検索", en: "Search by destination URL" },
    webhooksPageTableHeaderID: { ja: "ID", en: "ID" },
    webhooksPageTableHeaderPayloadURL: { ja: "送信先URL", en: "Destination URL" },
    webhooksPageTableHeaderEvents: { ja: "イベント", en: "Events" },
    webhooksPageTableHeaderIsActive: { ja: "有効化", en: "Is Active" },
    webhooksPageAddWebhookPlaceholder: { ja: "新しい送信先を追加", en: "Add new webhook" },
    webhookModalTitle: { ja: "新規Webhookを作成", en: "Add a new Webhook" },
    webhookModalDestinationURL: { ja: "送信先URL", en: "Destination URL" },
    webhookModalSecretKey: { ja: "シークレットキー", en: "Secret Key" },
    webhookModalTrackingObject: { ja: "トラッキングオブジェクト", en: "Tracking Object" },
    webhookModalContentType: { ja: "コンテントタイプ", en: "Content Type" },
    webhookModalActivation: { ja: "有効化", en: "Activation" },
    webhookModalAddWebhook: { ja: "Webhookを追加", en: "Add Webhook" },
    webhookDetailTitle: { ja: "Webhook詳細", en: "Webhook Details" },
    webhookDetailSave: { ja: "Webhookを更新", en: "Update Webhook" },
    webhookDetailDelete: { ja: "Webhookを削除", en: "Delete WebHook" },
    webhookDetailID: { ja: "ID", en: "ID" },
    webhookDetailDestinationURL: { ja: "送信先URL", en: "Destination URL" },
    webhookDetailSecretKey: { ja: "シークレットキー", en: "Secret Key" },
    webhookDetailTrackingObject: { ja: "トラッキングオブジェクト", en: "Tracking OBject" },
    webhookDetailContentType: { ja: "コンテントタイプ", en: "Content Type" },
    webhookDetailActivation: { ja: "有効化", en: "Activation" },
    webhookDetailCreationDate: { ja: "作成日時", en: "Creation Date" },
    webhookDetailUpdateDateAndTime: { ja: "更新日時", en: "Date and Time" },
    webhookDetailDeleteConfirmation: { ja: "削除しますか？", en: "Are you sure you want to delete？" },
    connectionPage: { ja: "コネクション", en: "Connection" },
    connectionPageSearchPlaceHolder: { ja: "名前, 接続先で検索", en: "Search by name or connection" },
    connectionPageID: { ja: "ID", en: "ID" },
    connectionPageAddPlaceholder: { ja: "新しい接続先を追加", en: "Add new connection" },
    connectionPageName: { ja: "名前", en: "Name" },
    connectionPageAccessPoint: { ja: "接続先", en: "Access Point" },
    connectionPageActivation: { ja: "有効化", en: "Activation" },
    connectionDetailDeleteConfirmation: { ja: "削除しますか？", en: "Are you sure you want to delete？" },
    connectionDetailUpdateConnection: { ja: "コネクションを更新", en: "Update Connection" },
    connectionDetailDeleteConnection: { ja: "コネクションを削除", en: "Delete Connection" },
    connectionDetailDestinationDetails: { ja: "接続先詳細", en: "Connection Details" },
    connectionDetailName: { ja: "名前", en: "Name" },
    connectionDetailAccessPoint: { ja: "接続先", en: "Access Point" },
    connectionDetailSettingInfo: { ja: "接続先設定情報", en: "Connection Settings Information" },
    connectionDetailConfiguration: { ja: "設定", en: "Settings" },
    connectionDetailPreview: { ja: "プレビュー", en: "Preview" },
    connectionDetailConnectionSettingsInfoPlaceholder: { ja: "接続先設定情報", en: "Connection Settings Information" },
    connectionDetailCallbackUrl: { ja: "コールバックURL", en: "Callback URL" },
    connectionDetailActivation: { ja: "有効化", en: "Activation" },
    connectionDetailServiceCode: { ja: "サービスコード", en: "Service Code" },
    connectionModalAddConnection: { ja: "コネクションを追加", en: "Add Connection" },
    connectionModalNewConnection: { ja: "新規コネクションを追加", en: "New Connection" },
    connectionModalName: { ja: "名前", en: "Name" },
    connectionModalSettingsInfo: { ja: "接続先設定情報", en: "Connection Settings Info" },
    connectionModalSettings: { ja: "設定", en: "Settings" },
    connectionModalPreview: { ja: "プレビュー", en: "Preview" },
    connectionModalConnectionSettingPlaceholder: { ja: "接続先設定情報", en: "Connection Setting Information" },
    connectionModalActivation: { ja: "有効化", en: "Activation" },
    connectionModalServiceCode: { ja: "サービスコード", en: "Service Code" },
    connectionLabelGoogle: { ja: "Google Login", en: "Google Login" },
    connectionLabelFacebook: { ja: "Facebook Login", en: "Facebook Login" },
    connectionLabelTwitter: { ja: "Twitter Login", en: "Twitter Login" },
    connectionLabelLine: { ja: "Line", en: "Line" },
    connectionLabelGlobalLine: { ja: "五反田商事 Global Line Platform", en: "Global Line Platform" },
    connectionLabelSpiralEC: { ja: "Spiral EC", en: "Spiral EC" },
    connectionLabelMailgun: { ja: "Mailgun Notification", en: "Mailgun Notification" },
    connectionLabelHipchat: { ja: "Hipchat Notification", en: "Hipchat Notification" },
    connectionLabelTwilio: { ja: "Twilio Notification", en: "Twilio Nortification" },
    connectionLabelIBMWatson: { ja: "IBM Watson NL Classifer", en: "IBM Watson NL Classifer" },
    connectionLabelIBMWatsonDialog: { ja: "IBM Watson Dialog", en: "IBM Watson Dialog" },
    connectionLabelYahoo: { ja: "Yahoo キーワード抽出API", en: "Yahoo Keyword extraction API" },
    connectionLabelOkSkyNLCCF: { ja: "OK SKY NLCCF", en: "OK SKY NLCCF" },
    connectionLabelPusher: { ja: "Pusher", en: "Pusher" },
    connectionLabelIosDevicePush: { ja: "iOS Device Push", en: "iOS Device Push" },
    connectionLabelVisionaryAPI: { ja: "Visionary API", en: "Visionary API" },
    connectionLabelIOSSandboxDevicePush: { ja: "iOS Sandbox Device Push", en: "iOS Sandbox Device Push" },
    connectionLabelAndroidDevicePush: { ja: "Android Device Push", en: "Android Device Push" },
    connectionLabelUserCustom: { ja: "User Custom", en: "User Custom" },
    connectionLabelLineBotApi: { ja: "Line Bot API", en: "Line Bot API" },
    connectionLabelNTTDataCCQA: { ja: "NTTData CCQA", en: "NTTData CCQA" },
    connectionLabelTwilioTextMessaging: { ja: "Twilio Text Messaging", en: "Twilio Text Messaging" },
    connectionLabelMessengerApi: { ja: "Messenger Api", en: "Messenger Api" },
    connectionLabelLineMessagingApi: { ja: "Line Messaging Api", en: "Line Messaging Api" },
    connectionLabelSalesforceApi: { ja: "Salesforce", en: "Salesforce" },
    connectionLabelOpenAiApi: { ja: "OpenAi", en: "OpenAi" },
    reportPage: { ja: "レポート", en: "Reports" },
    reportPageSearchCondition: { ja: "検索条件", en: "Search Condition" },
    reportPageStartPlaceholder: { ja: "集計期間 ここから", en: "Start Period" },
    reportPageEndPlaceholder: { ja: "集計期間 ここまで", en: "End Period" },
    reportPageSupportStats: { ja: "サポートの統計情報", en: "Support Stats" },
    reportPageTotalNumber: { ja: "数値 (合計)", en: "Supports (Total)" },
    reportPageDaily: { ja: "デイリー", en: "Daily" },
    reportPageInitialResponseTime: { ja: "初回の対応時間", en: "Initial Response Time" },
    reportPageHourly: { ja: "時間別", en: "Hourly" },
    reportPageNumberOfOperators: { ja: "関わったオペレータ数", en: "Number of Operators" },
    reportPageTotal: { ja: "合計", en: "Total" },
    reportPageAverageInitialResponseTime: { ja: "初回の対応時間 (平均値)", en: "Initial Response Time (Average)" },
    reportPageSupportedCurrentValue: { ja: "対応中サポート (現在値)", en: "Supported (current value)" },
    reportPageRankingOperator: { ja: "オペレータ別ランキング", en: "Ranking by operators" },
    reportPageName: { ja: "名前", en: "Name" },
    reportPageAssignedSupportTotal: { ja: "割り当てられたサポート数 (合計)", en: "Assigned number of support (total)" },
    reportPageTotalSentMessages: { ja: "メッセージ送信数 (合計)", en: "Messages sent (total)" },
    reportPageAverageTimeToFirstMessage: { ja: "初回のメッセージまでの時間 (平均値)", en: "Time to first message (average value)" },
    reportPageFAQStatistics: { ja: "FAQの統計情報", en: "FAQ statistics" },
    reportPageNumber: { ja: "数値", en: "Number" },
    reportPageTotalFAQs: { ja: "FAQ数 (合計)", en: "FAQs (total)" },
    reportPageFrequencyRating: { ja: "FAQ別利用回数ランキング", en: "Frequency ranking by FAQ" },
    reportPageTotalMessagesUsed: { ja: "メッセージ利用回数 (合計)", en: "Number of messages used (total)" },
    reportPageNumberTimesUsed: { ja: "利用回数", en: "Number of times used" },
    reportPageSelectStartAndEndDate: { ja: "開始日と終了日を選択してください", en: "Please select start date and end date" },
    reportPageVisitedPages: { ja: "訪問したページ", en: "Visited Pages" },
    reportPageLabelNewSupports: { ja: "新規サポート", en: "New Supports" },
    reportPageLabelEndedSupports: { ja: "終了サポート", en: "Ended Supports" },
    reportPageLabelNumberOfNewSupports: { ja: "新規Support数", en: "Number of new supports" },
    reportPageLabelNumberOfEndedSupports: { ja: "終了Support数", en: "Number of ended supports" },
    reportPageLabelFAQUsage: { ja: "FAQ利用回数", en: "FAQ Usage" },
    reportPageLabelNumberOfFAQsCreated: { ja: "FAQ作成数", en: "Number of FAQs Created" },
    dashboardSupportingCounts: { ja: "対応中", en: "Supporting" },
    dashboardNotRepliedCounts: { ja: "未返信", en: "Not replied" },
    dashboardRepliedCounts: { ja: "返信済", en: "Replied" },
    dashboardAwaitingCounts: { ja: "接客待ち", en: "Awaiting" },
    dashboardClosedRoomsCounts: { ja: "対応件数", en: "Closed" },
    dashboardClosedRoomsTodayLabel: { ja: "(当日)", en: "(Today)" },
    dashboardMaximumAwaitingTime: { ja: "最長待ち時間", en: "Maximum awaiting time" },
    dashboardAverageAwaitingTime: { ja: "平均待ち時間", en: "Average awaiting time" },
    dashboardPermission: { ja: "この機能はスーパーバイザ、管理者向けです。", en: "This function only for Supervisor or Administrator." },
    dashboardAwaitingTimeDescription1: {
        ja: "※ 「待ち時間」は、来訪者の自動アサインに失敗し、接客待ちに入ったときに記録が開始されます。",
        en: "* \"Awaiting Time\" start recording when the automatic operator assignment was failed and it placed to a waiting list."
    },
    dashboardAwaitingTimeDescription2: {
        ja: "※ 「待ち時間」のカウントは、オペレータをアサインしてからメッセージを送信することで止まります（オペレータをアサインせずにメッセージを送信しても「待ち時間」のカウントは止まりません）。",
        en: "* \"Awaiting Time\" will stop recording when an operator send a message after assigned. (It does not stop recording when only message was sent without assigning an operator)"
    },
    dashboardAwaitingTimeDescription3: {
        ja: "※ 「対応件数（当日）」は「無視」で除外したサポートをカウントしません。",
        en: "* \"Closed (Today)\" not including \"Ignored\" support rooms."
    },
    usagePageMenuText: { ja: "利用量", en: "Usage" },
    messageInputSendOnEnterToggle: { ja: "↩︎️で送信", en: "↩︎️Send On Enter" },
    messageInputSendOnEnterMessage: {
        ja: "メッセージを入力してください (Shift+Enterで改行)",
        en: "Please write a message press (Shift+Enter) to send"
    },
    messageInputNotSendOnEnterMessage: {
        ja: "メッセージを入力してください (Shift+Enterで送信)",
        en: "Please write a message press Enter key to send"
    },
    editClientDetail: { ja: "チャットアイコン", en: "Client Detail" },
    editChatWindow: { ja: "チャット画面", en: "Chat Window" },
    chatHasNotStartedMessage: { ja: "チャットはまだ始まっていません", en: "The chat hasn't started yet" },
    requiredMessage: { ja: "必須", en: "required" },
    cancelMessage: { ja: "キャンセル", en: "Cancel" },
    createMessage: { ja: "作成", en: "Create" },
    editMessage: { ja: "編集", en: "Edit" },
    saveMessage: { ja: "保存", en: "Save" },
    deleteMessage: { ja: "削除", en: "Delete" },
    setupMessage: { ja: "設定", en: "Setup" },
    alreadyRegisteredMessage: { ja: "既に登録されています", en: "Already Registered" },
    confirmMessage: { ja: "確認", en: "Confirm" },
    logout: { ja: "ログアウト", en: "Logout" },
    personalSettings: { ja: "個人設定", en: "Your Settings" },
    numberSelected: { ja: "を選択中", en: "Selected" },
    resultCounter: { ja: "件", en: "Results" },
    selectedCounter: { ja: "件", en: "Selected" },
    insertMessage: { ja: "挿入", en: "Insert" },
    checkWhatYouChecked: { ja: "チェックしたものを", en: "" },
    checkWhatYouCheckedEdit: { ja: "チェックしたものを編集", en: "" },
    configureTenant: { ja: "テナント設定", en: "Configure Tenant" },
    submit: { ja: "送信", en: "Submit" },
    yes: { ja: "はい", en: "yes" },
    ok: { ja: "はい", en: "OK" },
    add: { ja: "追加", en: "Add" },
    sendTransfer: { ja: "ファイル転送", en: "File transfer" },
    year: { ja: "年", en: "year" },
    month: { ja: "月", en: "month" },
    day: { ja: "日", en: "day" },
    days: { ja: "日", en: "days" },
    minutesAgo: { ja: "分前", en: "minutes ago" },
    minutes: { ja: "分", en: "minutes" },
    minute: { ja: "分", en: "minute" },
    hours: { ja: "時間", en: "hours" },
    hour: { ja: "時", en: "hour" },
    aFewMinutes: { ja: "数分-", en: "A few minutes" },
    justSent: { ja: "ついさっき", en: "A moment ago" },
    ago: { ja: "前", en: " ago" },
    dayOfWeekLabelMonday: { ja: "月", en: "M" },
    dayOfWeekLabelTuesday: { ja: "火", en: "T" },
    dayOfWeekLabelWednesday: { ja: "水", en: "W" },
    dayOfWeekLabelThursday: { ja: "木", en: "R" },
    dayOfWeekLabelFriday: { ja: "金", en: "F" },
    dayOfWeekLabelSaturday: { ja: "土", en: "S" },
    dayOfWeekLabelSunday: { ja: "日", en: "Su" },
    monday: { ja: "月", en: "Monday" },
    tuesday: { ja: "火", en: "Tuesday" },
    wednesday: { ja: "水", en: "Wednesday" },
    thursday: { ja: "木", en: "Thursday" },
    friday: { ja: "金", en: "Friday" },
    saturday: { ja: "土", en: "Saturday" },
    sunday: { ja: "日", en: "Sunday" },
    dayOfTheWeek: { ja: "曜日", en: "" },
    daysOfTheWeek: { ja: ["日", "月", "火", "水", "木", "金", "土"], en: ["S", "M", "T", "W", "R", "F", "S"] },
    monthInTheYear: {
        ja: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    from: { ja: "から", en: "from" },
    to: { ja: "まで", en: "to" },
    more: { ja: "さらに", en: "more" },
    alertDanger: {
        ja: "サーバーへのリクエスト中にエラーが発生しました。しばらくしてからもう一度お試しください。",
        en: "An error has occurred.  Please try again later."
    },
    alertSuccess: { ja: "サーバーとの接続が復旧しました！", en: "Connection Restored!" },
    alertWarning: {
        ja: "サーバーとの接続ができません。 WifiやLAN設定を再度ご確認ください",
        en: "Unable to connect.  Please check WiFi and LAN settings."
    },
    alertDialogSupportsMessageDeleteConfirm: { ja: "削除はやり直せません。本当に宜しいですか？", en: "Delete cannot be undone. are you sure?" },
    alertDialogMasterKeywordDeleteConfirm: { ja: "削除はやり直せません。本当に宜しいですか？", en: "Delete cannot be undone. are you sure?" },
    statusAvailable: { ja: "接客中", en: "Available" },
    statusAway: { ja: "離席中", en: "Away" },
    statusBusy: { ja: "取り込み中", en: "Busy" },
    statusOffline: { ja: "オフライン", en: "Offline" },
    active: { ja: "アクティブ", en: "active" },
    inactive: { ja: "非アクティブ", en: "inactive" },
    searchPlaceholder: { ja: "検索", en: "Search" },
    rightSidebarHeaderTitle: { ja: "ルーム情報", en: "Room Information" },
    rightSidebarHelpRoomHeader: { ja: "ヘルプルームを表示", en: "Show Help Room" },
    rightSidebarOperatorsHeader: { ja: "接客者一覧", en: "Support members" },
    rightSidebarNoSupportHistoryMessage: { ja: "サポート履歴なし", en: "No Support History Yet." },
    rightSidebarMemoHeader: { ja: "メモ", en: "Memo" },
    rightSidebarMemoPlaceholder: { ja: "業務用メモを残すことができます。", en: "Write a shared message to everyone in this room" },
    rightSidebarLinkHeader: { ja: "リンク", en: " Links" },
    rightSidebarFileHeader: { ja: "ファイル", en: " Files" },
    rightSidebarHideSidebarBalloon: { ja: "サイドバーを非表示", en: "Hide Sidebar" },
    rightSidebarShowSidebarBalloon: { ja: "サイドバーを表示", en: "Show Sidebar" },
    templateResponseQuestion: { ja: "質問", en: "Title" },
    templateResponseAnswer: { ja: "答え", en: "WidgetMessage Text" },
    templateResponseAnswerViewImage: { ja: "表示イメージ", en: "view images" },
    templateResponseAnswerHtmlKind: { ja: "HTML表示", en: "view html" },
    templateResponseTags: { ja: "タグ", en: "Tags" },
    templateResponseUpdateVideoTagModal: { ja: "動画設定", en: "Setting up videos" },
    templateResponseUpdateYoutubeModal: { ja: "Youtube設定", en: "Setting up Youtube" },
    templateResponseUpdateYoutubeIdTitle: { ja: "Youtubeの動画のID", en: "youtube's id" },
    templateResponseUpdateYoutubeIdCaption: { ja: "※Youtubeの動画のIDとは、表示したい動画のYoutubeページ「https://www.youtube.com/watch?v=[Youtubeの動画のID]」となります", en: "*The ID of the Youtube video is the Youtube page \"https://www.youtube.com/watch?v=[ID of the Youtube video]\" of the video you wish to display" },
    templateResponseShowVideoTagModal: { ja: "動画確認", en: "show videos" },
    templateResponseShowYoutubeModal: { ja: "Youtube確認", en: "show youtube" },
    templateResponseTagPlaceholder: { ja: "Enterキーでタグを区切ります", en: "Use the Enter key to seperate tags" },
    templateResponseInsertMessage: { ja: "FAQの挿入", en: "Insert FAQ" },
    templateResponseAddNewMessage: { ja: "新しいFAQ", en: "Add new FAQ" },
    templateResponseModalTitle: { ja: "動画の設定", en: "Add a movie" },
    templateResponseHtmlTagModalTitle: { ja: "表示イメージ", en: "view show image" },
    templateResponseYoutubeModalTitle: { ja: "Youtubeの設定", en: "Add a youtube" },
    templateResponseModalUploadVideoCaption: { ja: "※現在アップロードされているファイルはすべて上書きされます", en: "*All currently uploaded files will be overwritten" },
    templateResponseModalDeletedVideoMessage: { ja: "動画を削除しました", en: "Video removed" },
    templateResponseModalDeletedYoutubeMessage: { ja: "Youtubeの設定を削除しました", en: "Youtube settings removed" },
    templateResponseModalDeletedVideoConfirmation: { ja: "削除しても良いですか？", en: "Are you sure you want to delete" },
    templateResponseModalVideoTitle: { ja: "動画", en: "Video" },
    templateResponseModalVideoThumbnailTitle: { ja: "動画サムネイル画像", en: "Video's thumbnail image" },
    templateResponseModalVideoThumbnailUploadTitle: { ja: "動画サムネイル画像アップロード", en: "Video's thumbnail image upload" },
    templateResponseModalThumbnailDelete: { ja: "初期画像に戻す", en: "return default image" },
    templateResponseModalVideoSettingCaption: { ja: "※設定ボタンを押下しても、保存ボタンを押下するまでは保存されません。", en: "*The setting will not be saved until the Save button is pressed" },
    templateResponseModalUpdateDefaultVideoTagThumbnailMessage: { ja: "動画サムネイル画像を更新しました", en: "Video thumbnail image updated" },
    surveyInsertMessage: { ja: "アンケートの挿入", en: "Insert Survey" },
    userEventLogRowObjIn: { ja: "に入りました", en: "Entered Room" },
    userEventLogRowObjOut: { ja: "を出ました", en: "Exited Room" },
    userEventLogRowUserSupported: { ja: "サポートが始まりました", en: "Support Started" },
    room: { ja: "ルーム", en: "Room" },
    unknownRoom: { ja: "不明なルーム", en: "Unknown Room" },
    chatTooltipContents: { ja: "個人チャット", en: "Personal Chat" },
    userListTooltipContents: { ja: "ユーザー一覧", en: "User List" },
    feedbackToastDefault: { ja: "完了しました", en: "Finished" },
    feedbackToastClientSaved: { ja: "ウィジェットのデータを保存しました", en: "Client Saved" },
    alertSettingsPageSaved: { ja: "データを保存しました", en: "Your data has been saved" },
    feedbackToastConnectionAdded: { ja: "コネクションを追加されました", en: "The connection was added" },
    feedbackToastConnectionUpdated: { ja: "コネクションを編集されました", en: "The connection was updated" },
    feedbackToastConnectionDeleted: { ja: "コネクションを削除されました", en: "The connection was deleted" },
    feedbackToastMasterKeywordSaved: { ja: "マスターキーワードが保存されました", en: "Master Keyword Saved" },
    feedbackToastMessageEdited: { ja: "メッセージが編集されました", en: "The message was edited" },
    feedbackToastMessageDeleted: { ja: "メッセージが削除されました", en: "The message was deleted" },
    feedbackToastMasterKeywordDeleted: { ja: "マスターキーワードが削除されました", en: "The master keyword was deleted" },
    feedbackToastMessageCopied: { ja: "メッセージがコピーされました", en: "The message was copied to clipboard" },
    feedbackToastDraftMessageCopied: { ja: "下書きメッセージがコピーされました", en: "The Draft message was copied to clipboard" },
    feedbackToastTextCopied: { ja: "テキストがコピーされました", en: "The text was copied" },
    feedbackToastSearchingForMessage: { ja: "メッセージを検索結果を取得しました", en: "WidgetMessage search completed" },
    feedbackToastMessageSearchPageMessagesDownloaded: {
        ja: "全メッセージがダウンロードされました",
        en: "All the messages have been downloaded to CSV"
    },
    feedbackToastSearchingForExports: { ja: "エクスポートを検索しました", en: "The exports have been searched" },
    feedbackToastOperatorPageMembersSaved: { ja: "メンバーが保存されました", en: "Member saved" },
    feedbackToastOperatorActivated: { ja: "アクティブになりました", en: "The operator was activated" },
    feedbackToastOperatorDeactivated: { ja: "非アクティブになりました", en: "The operator was deactivated" },
    feedbackToastDraftMessageRejected: { ja: "下書きメッセージは却下されました", en: "The message was rejected" },
    feedbackToastDraftMessageApproved: { ja: "下書きメッセージは承認されました", en: "The message was approved" },
    feedbackToastPastSupportsSearched: { ja: "過去のサポートを検索しました", en: "THe past supports have been searched" },
    feedbackToastAllPastSupportsDownloaded: { ja: "全サポートがダウンロードされました", en: "All past supports downloaded" },
    feedbackToastNewMemberAddedToHelpRoom: { ja: "新しいメンバーがヘルプルームに追加されました", en: "New Member added to helped room" },
    feedbackToastFAQDeleted: { ja: "FAQが削除されました", en: "FAQ deleted" },
    feedbackToastMemoSaved: { ja: "メモを保存しました", en: "The memo was saved" },
    feedbackToastFAQCopied: { ja: "FAQがコピーされました", en: "The FAQ was copied" },
    feedbackToastFAQSaved: { ja: "FAQが保存されました", en: "The FAQ was saved" },
    feedbackToastWebhookAdded: { ja: "Webhookが追加されました", en: "The WebHook was added" },
    feedbackToastWebhookUpdated: { ja: "Webhookが編集されました", en: "The WebHook was updated" },
    feedbackToastWebhookDeleted: { ja: "Webhookが削除されました", en: "The WebHook was deleted" },
    sendOnEnterBalloonOn: { ja: "Enterキーでメッセージ送信: ON", en: "Send message with ENTER key: ON" },
    sendOnEnterBalloonOff: { ja: "Enterキーでメッセージ送信: OFF", en: "Send message with ENTER key: OFF" },
    duplicateMessageModalMessage: { ja: "直前に同じメッセージを送信しています", en: "Duplicate WidgetMessage sent" },
    draftMessageModalMessageOne: { ja: "メッセージは下書きメッセージに保存されました", en: "Support Draft message saved" },
    draftMessageModalMessageTwo: { ja: "承認されるまでメッセージの送信はされません", en: "Your message will not be sent until approved " },
    addMemberToGroupModalTitle: { ja: "ユーザーをルームに追加", en: "Add User to Room" },
    customerTyping: { ja: "来訪者が入力しています", en: "Customer is typing" },
    v2SendOnMessage: { ja: "メッセージを入力", en: "Please input a message" },
    v2TimeFormatSlashSeparator: { ja: "YYYY/MM/DD", en: "YYYY/MM/DD" },
    v2TimeFormatNormal: { ja: "YYYY年MM月DD日", en: "YYYY year MM month DD day" },
    v2TimeFormatDotSeparator: { ja: "YYYY.MM.DD", en: "YYYY.MM.DD" },
    showMore: { ja: "ひろげる", en: "Show more" },
    showLess: { ja: "もどす", en: "Show less" },
    newCounter: { ja: "未読", en: "new" },
    error: { ja: "エラー", en: "Error" },
    unknown: { ja: "不明", en: "Unknown" },
    operatorLabel: { ja: "オペレータ", en: "Operator" },
    supervisorLabel: { ja: "スーパーバイザ", en: "Supervisor" },
    administratorLabel: { ja: "管理者", en: "Administrator" },
    customerLabel: { ja: "顧客", en: "Customer" },
    dropdownPlaceholder: { ja: "担当者を選択", en: "Select an Operator" },
    message: { ja: "メッセージ", en: "Message" },
    page: { ja: "ページ", en: "Page" },
    count: { ja: "カウント", en: "Count" },
    messageRead: { ja: "既読", en: "read" },
    fileDragDropAreaMessage: { ja: "ここにドロップしてください", en: "Drop here" },
    widgetRoomInputMessage: { ja: "メッセージを送信してください", en: "Send a message..." },
    widgetLastMessageReferToCustomer: { ja: "あなた", en: "You" },
    widgetMessageSummaryImage: { ja: "画像を送信しました", en: "Sent an image" },
    widgetMessageSummaryVideo: { ja: "ビデオを送信しました", en: "Sent a video" },
    widgetMessageSummaryVideoTag: { ja: "ビデオを送信しました", en: "Sent a video" },
    widgetMessageSummaryYoutube: { ja: "Youtube動画を送信しました", en: "Sent a Youtube" },
    widgetMessageSummaryFile: { ja: "ファイルを送信しました", en: "Sent a file" },
    widgetMessageSummaryAudioFile: { ja: "オーディオファイルを送信しました", en: "Sent an audio file" },
    widgetCreateRoomDefaultText: { ja: "会話を開始", en: "new conversation" },
    widgetClientDefaultDescription: { ja: "お困りごとはございませんか？", en: "Ask us anything, or share your feedback." },
    widgetClientDefaultReplyGuidance: { ja: "順番に返信いたします", en: "You will be connected to an operator within a few minutes" },
    widgetGetEmailUpdates: { ja: "メール通知の登録", en: "Get Email Updates" },
    email: { ja: "メール", en: "E-Mail" },
    emailInvalidErrorMessage: { ja: "無効なメール", en: "Invalid Email" },
    tenantSettings: { ja: "テナント設定", en: "Tenant Settings" },
    tenantName: { ja: "名前", en: "Tenant Name" },
    tenantCustomerRedirectUrl: { ja: "認証エラー時のリダイレクト先URL", en: "Customer Redirect URL" },
    tenantDefaultCustomerAvatarUrl: { ja: "来訪者のデフォルトアイコンURL", en: "Customer Avatar URL" },
    tenantDefaultAvatarUrl: { ja: "接客者のデフォルトアイコンURL", en: "Default Avatar URL" },
    tenantBitlyAccessToken: { ja: "bit.lyアクセストークン", en: "Bitly Access Token" },
    tenantCreateUserEmail: { ja: "テナント作成者", en: "Create User Email" },
    tenantHistoryDayLimit: { ja: "サポート履歴読込期間", en: "History day Limit" },
    tenantValidIps: { ja: "有効なIP", en: "Valid IPS" },
    tenantOtherConfigurations: { ja: "その他の設定", en: "Other Configurations" },
    tenantEnableAssignWithOrderOfRole: { ja: "オペレータ→スーパーバイザ→管理者の順にサポートアサインを行う", en: "Assign support with order by role (operator > supervisor > administrator)" },
    tenantPlan: { ja: "プラン", en: "Plan Information" },
    tenantConfirmPlan: { ja: "確定", en: "Confirm" },
    tenantCurrentPlan: { ja: "現在のプラン", en: "Current Plan" },
    tenantNewPlan: { ja: "新しいプラン", en: "New Plan" },
    tenantCardPaymentType: { ja: "カード支払方法", en: "Card payment type" },
    tenantCardPaymentInfo: { ja: "カード情報", en: "Card information" },
    tenantCantChangePlan: { ja: "プランのダウングレードは月に１回のみ可能です", en: "Can downgrade plan only once in month" },
    tenantPaymentPatternAddNewWithSetDefault: { ja: "新しいカードで支払う", en: "Add new card and pay with it" },
    tenantPaymentPatternUseSelectedWithSetDefault: { ja: "登録済みカードで支払う", en: "Pay with existing card" },
    fileInputDragAndDropDescription: { ja: "ファイルをドラッグ＆ドロップしてください または", en: "Please drag and drop the file here or" },
    fileInputButtonText: { ja: "ファイルを選択", en: "select the file" },
    fileInputFileSelectionDescription: {
        ja: "(Shiftキーを押しながらファイルを複数選択可能)",
        en: "(multiple file selection is done by holding the shift-key when selecting)"
    },
    fileUploadLoadingMessage: { ja: "File uploading...", en: "File uploading..." },
    thumbnailFileUploadLoadingMessage: { ja: "Thumbnail File uploading...", en: "Thumbnail File uploading..." },
    commonExplanatoryNoteName: { ja: "※凡例:", en: "* Explanatory note:" },
};
